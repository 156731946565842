import { Spinner } from "@appsmith/wds";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Document, Page, pdfjs } from "react-pdf";
import type { PageCallback } from "react-pdf/dist/cjs/shared/types";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import type { PdfCitationHighlightAnchorCoordinate } from "../../types";
import styles from "./styles.module.css";
import type { PdfDocumentViewerProps } from "./types";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const options = {
  cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
  cMapPacked: true,
};

export const PdfDocumentViewer = ({
  citation,
  isResizing,
  ...rest
}: PdfDocumentViewerProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [allPageLoaded, setAllPageLoaded] = useState(false);
  const { end, start } = citation.coordinates;

  const findCitationHighlight = useCallback(
    (pageNumber: number): Element | null => {
      return (
        document
          .querySelector(`.react-pdf__Page[data-page-number='${pageNumber}']`)
          ?.querySelector(`.${styles.citationHighlight}`) || null
      );
    },
    [],
  );

  const scrollToCitation = useCallback((): void => {
    const node = findCitationHighlight(start.pageNumber);

    if (!node) return;

    node.scrollIntoView({ block: "center" });
  }, [start.pageNumber, findCitationHighlight]);

  // https://github.com/wojtekmaj/react-pdf/issues/398#issuecomment-501237672
  const makeOnAllPagesRenderedCallback = (onAllPagesRendered: () => void) => {
    const pages: boolean[] = [];

    function ref(pageComponent: React.RefObject<PageCallback>) {
      if (!pageComponent) {
        return;
      }

      // Register Page component at mount
      pages[pageComponent.current?._pageIndex] = false;
    }

    function onRenderSuccess(page: PageCallback) {
      // Register page as rendered
      pages[page._pageIndex] = true;

      // If all pages registered are now rendered, call the callback
      if (pages.every(Boolean)) {
        onAllPagesRendered();
      }
    }

    return {
      ref,
      onRenderSuccess,
    };
  };

  useEffect(() => {
    if (!allPageLoaded || isResizing) return;

    scrollToCitation();
  }, [allPageLoaded, isResizing, scrollToCitation, citation]);

  useEffect(
    function resetStateWhenDocumentChanged() {
      setAllPageLoaded(false);
      setTotalPageCount(0);
    },
    [citation.name],
  );

  useEffect(
    function updateContainerWidthAfterResizing() {
      if (!containerRef.current || isResizing) return;

      setContainerWidth(containerRef.current?.clientWidth || 0);
    },
    [isResizing],
  );

  const pageProps = useMemo(
    () =>
      makeOnAllPagesRenderedCallback(() => {
        setAllPageLoaded(true);
      }),
    [],
  );

  const showCitationHighlight = (pageNumber: number) => {
    return pageNumber >= start.pageNumber && pageNumber <= end.pageNumber;
  };

  const isSinglePageCitation = () => start.pageNumber === end.pageNumber;

  const resolveCitationHighlightStyle = ({
    end,
    pageNumber,
    start,
  }: {
    end: PdfCitationHighlightAnchorCoordinate;
    start: PdfCitationHighlightAnchorCoordinate;
    pageNumber: number;
  }) => {
    const left = start.x;
    const width = end.x - start.x;
    let top = 0;
    let height = 0;

    if (isSinglePageCitation()) {
      top = start.y;
      height = end.y - start.y;
    } else {
      // Stretch the highlight to the bottom of the first page
      if (pageNumber === start.pageNumber) {
        top = start.y;
        height = 1 - start.y;
      }
      // Cover intermediate pages entirely
      else if (pageNumber < end.pageNumber) {
        top = 0;
        height = 1;
      }
      // Stretch the highlight to the top of the last page
      else {
        top = 0;
        height = end.y;
      }
    }

    return {
      left: `${left * 100}%`,
      top: `${top * 100}%`,
      width: `${width * 100}%`,
      height: `${height * 100}%`,
    };
  };

  return (
    <div className={styles.pdfDocumentViewer} {...rest} ref={containerRef}>
      {!allPageLoaded && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
      <div
        style={{
          ...((isResizing || !allPageLoaded) &&
            ({ opacity: "0" } as React.CSSProperties)),
        }}
      >
        <Document
          file={citation.sourceUrl}
          loading=""
          onLoadSuccess={({ numPages }) => setTotalPageCount(numPages)}
          options={options}
        >
          {Array.from(new Array(totalPageCount), (_, index) => (
            <Page
              key={`page_${index + 1}`}
              loading=""
              pageNumber={index + 1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              width={containerWidth}
              {...pageProps}
            >
              {showCitationHighlight(index + 1) && (
                <div
                  className={styles.citationHighlight}
                  style={resolveCitationHighlightStyle({
                    end,
                    start,
                    pageNumber: index + 1,
                  })}
                />
              )}
            </Page>
          ))}
        </Document>
      </div>
    </div>
  );
};
