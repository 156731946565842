import { Markdown, Spinner } from "@appsmith/wds";
import React, { useCallback, useEffect, useMemo } from "react";
import styles from "./styles.module.css";
import type { TextDocumentViewerProps } from "./types";
import { useTextFileLoader } from "../useTextFileLoader";

export const TextDocumentViewer = ({
  citation,
  ...rest
}: TextDocumentViewerProps) => {
  const contentNodeRef = React.useRef<HTMLDivElement>(null);
  const { content, hasError, isLoading } = useTextFileLoader(
    citation.sourceUrl,
  );

  useEffect(
    function scrollToNewHighlight() {
      // No need to scroll if content is not loaded yet
      if (isLoading || !content) return;

      scrollToCitation();
    },
    [citation, isLoading, content],
  );

  const highlightPassages = useCallback(
    (content: string, passage: string): string => {
      let highlightedContent = content;

      // We can't wrap the entire passage in a single <mark> tag.
      // Mark is inline and can't contain block elements like <p> or <div>.
      // Since the passage can span multiple paragraphs, we need to wrap each paragraph in a <mark> tag.
      highlightedContent = highlightedContent.replace(passage, (match) => {
        return match
          .split(/\n+/)
          .map((line) => line.trim())
          .map((line) => {
            return `<mark>${line}</mark>`;
          })
          .join("\n");
      });

      return highlightedContent;
    },
    [],
  );

  const scrollToCitation = (): void => {
    const node = contentNodeRef.current?.querySelector("mark");

    if (!node) return;

    node.scrollIntoView();
  };

  const highlightedContent = useMemo(() => {
    if (!content) return null;

    return highlightPassages(content, citation.passage);
  }, [content, citation, highlightPassages]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      );
    }

    if (hasError) {
      return <div>{"Can't load the file..."}</div>;
    }

    if (highlightedContent) {
      return <Markdown ref={contentNodeRef}>{highlightedContent}</Markdown>;
    }

    return null;
  };

  return (
    <div className={styles.textDocumentViewer} {...rest}>
      {renderContent()}
    </div>
  );
};
