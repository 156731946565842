import {
  Flex,
  Sidebar,
  SidebarProvider,
  type SidebarState,
} from "@appsmith/wds";
import React, { useCallback, useState } from "react";

import { useAIChatHeight } from "../hooks/useAIChatHeight";
import { ChatCitationEmpty } from "./ChatCitationEmpty";
import { ChatHeader } from "./ChatHeader";
import { ChatInputSection } from "./ChatInputSection";
import { ChatMessageList } from "./ChatMessageList";
import { MarkdownDocumentViewer } from "./documentViewers/MarkdownDocumentViewer";
import { PdfDocumentViewer } from "./documentViewers/PdfDocumentViewer";
import { TextDocumentViewer } from "./documentViewers/TextDocumentViewer";
import styles from "./styles.module.css";
import { type AIChatProps, type Citation } from "./types";

export const AIChat = (props: AIChatProps) => {
  const {
    chatDescription,
    chatTitle,
    isThreadLoading,
    isWaitingForResponse = false,
    onApplyAssistantSuggestion,
    onAttachFiles,
    onDeleteThread,
    onPromptChange,
    onSubmit,
    prompt,
    promptInputPlaceholder,
    size,
    thread,
  } = props;
  const { search } = window.location;
  const queryParams = new URLSearchParams(search);
  const isEmbed = queryParams.get("embed") === "true";
  const chatHeight = useAIChatHeight(size, isEmbed);
  const isDeleteThreadDisabled =
    thread.length === 1 || isThreadLoading || isWaitingForResponse;
  const [selectedCitation, setSelectedCitation] = useState<Citation | null>(
    null,
  );
  const [sidebarState, setSidebarState] = useState<SidebarState>("collapsed");

  const handleCitationSelect = useCallback((citation: Citation) => {
    setSelectedCitation(citation);
  }, []);

  const renderDocumentViewer = (citation: Citation, isAnimating: boolean) => {
    switch (citation.type) {
      case "PDF":
        return (
          <PdfDocumentViewer citation={citation} isResizing={isAnimating} />
        );
      case "TEXT":
        return <TextDocumentViewer citation={citation} />;
      case "MD":
        return <MarkdownDocumentViewer citation={citation} />;
      default:
        return null;
    }
  };

  return (
    <div
      className={styles.aiChat}
      style={
        {
          "--chat-height": chatHeight,
        } as React.CSSProperties
      }
    >
      <ChatHeader
        description={chatDescription}
        isDeleteThreadDisabled={isDeleteThreadDisabled}
        onDeleteThread={onDeleteThread}
        setSidebarState={setSidebarState}
        sidebarState={sidebarState}
        title={chatTitle}
      />
      <SidebarProvider
        className={styles.sidebarWrapper}
        onStateChange={setSidebarState}
        side="end"
        state={sidebarState}
      >
        <Flex direction="column" width="100%">
          <ChatMessageList
            isThreadLoading={isThreadLoading}
            onApplyAssistantSuggestion={onApplyAssistantSuggestion}
            onOpenCitation={handleCitationSelect}
            thread={thread}
          />
          <ChatInputSection
            isThreadLoading={isThreadLoading}
            isWaitingForResponse={isWaitingForResponse}
            onAttachFiles={onAttachFiles}
            onPromptChange={onPromptChange}
            onSubmit={onSubmit}
            prompt={prompt}
            promptInputPlaceholder={promptInputPlaceholder}
          />
        </Flex>
        <Sidebar title={selectedCitation?.name || ""}>
          {({ isAnimating, state }) => {
            if (state === "collapsed") return null;

            if (!selectedCitation) return <ChatCitationEmpty />;

            return renderDocumentViewer(selectedCitation, isAnimating);
          }}
        </Sidebar>
      </SidebarProvider>
    </div>
  );
};
